.hero_head {
    font-family: agency-fb, sans-serif;
    font-size: 5.4rem !important;
    line-height: 6rem !important;
    font-weight: 700 !important;
    color: #fff;
    margin: -10rem auto 0.1rem auto !important;
    text-transform: uppercase;
    color: transparent;
    max-width: 115rem !important;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    -webkit-mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
}


@media screen and (min-width:1200px) {
    .hero_head {
        font-family: agency-fb, sans-serif;
        font-size: 14rem !important;
        line-height: 15rem !important;
        font-weight: 700 !important;
        color: #fff;
        margin: -15rem auto 1.5rem auto !important;
        text-transform: uppercase;
        color: transparent;
        max-width: 115rem !important;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #fff;
        -webkit-mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
        mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    }
    


    }

.grid{
    display: grid !important;
}


.page-template-page-mintings #hero {
    width: 100%;
    height: auto;
    position: relative;
    background: #160f22;
    padding: 28rem 0 28rem 0;
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }


  .back_under{
    background-image: none !important;
  }



  .head_under{
    text-align: center;
    color: #fff;
    font-size: 15px;
    font-weight: 700;

  }
@media screen and (min-width:1200px) {
    .head_under{
        text-align: center;
        color: #fff;
        font-size: 29px;
        font-weight: 600;
        
      }
    }



    
  .head_under_second{
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
  }



  
@media screen and (min-width:1200px) {
    .head_under_second{
        text-align: center;
        color: #fff;
        font-size: 19px;
        font-weight: 600;
      }
    }

 


 .hsb {
        font-family: agency-fb, sans-serif;
        font-size: 3.4rem;
        line-height: 5.6rem;
        font-weight: 700;
        color: #10dce9;
        margin: 0 auto 3rem auto;
        text-align: center;
        text-transform: uppercase;
    }




      
@media screen and (min-width:1200px) {
    
 .hsb {
    font-family: agency-fb, sans-serif;
    font-size: 5.4rem;
    line-height: 5.6rem;
    font-weight: 700;
    color: #10dce9;
    margin: 0 auto 3rem auto;
    text-align: center;
    text-transform: uppercase;
}
    }


    .button {
        margin: 3.3rem auto 0 auto;
        position: relative;
        z-index: 5;
    }
    .page-template-page-home #hero .row .flex .button a {
        font-family: Akrobat, sans-serif;
        display: inline-block;
        font-size: 2rem;
        line-height: 2.2rem;
        color: #ffffff;
        margin: 0 auto;
        font-weight: 700;
        padding: 1.5rem 3.4rem;
        background-color: #935EE6;
        border-radius: 6px;
        transition: all .3s;
    }


    .video_css{
        justify-content: center !important;
        display: flex !important;
    }













    :root {
        --glow-color: hsl(186 100% 69%);
      }
      
      *,
      *::before,
      *::after {
        box-sizing: border-box;
      }














    .glowing-btn {
        position: relative;
        color: var(--glow-color);
        cursor: pointer;
        padding: 0.15em 1em;
        border: 0.11em solid var(--glow-color);
        border-radius: 0.45em;
        background: none;
        perspective: 2em;
        font-size: 0.4em;
        font-weight: 700;
        letter-spacing: 1em;
      
        -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
          0px 0px 0.5em 0px var(--glow-color);
        -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
          0px 0px 0.5em 0px var(--glow-color);
        box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
          0px 0px 0.5em 0px var(--glow-color);
        animation: border-flicker 2s linear infinite;
      }
      
      .glowing-txt {
        float: left;
        margin-right: -0.8em;
        -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
          0 0 0.45em var(--glow-color);
        -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
          0 0 0.45em var(--glow-color);
        text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
        animation: text-flicker 3s linear infinite;
      }
      
      .faulty-letter {
        opacity: 0.5;
        animation: faulty-flicker 1s linear infinite;
      }
      
      .glowing-btn::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.7;
        filter: blur(1em);
        transform: translateY(320%) rotateX(35deg) scale(1, 0.35);
        background: var(--glow-color);
        pointer-events: none;
      }
      
      .glowing-btn::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        background-color: var(--glow-color);
        box-shadow: 0 0 2em 0.2em var(--glow-color);
        transition: opacity 100ms linear;
      }
      
      .glowing-btn:hover {
        color: rgba(0, 0, 0, 0.8);
        text-shadow: none;
        animation: none;
      }
      
      .glowing-btn:hover .glowing-txt {
        animation: none;
      }
      
      .glowing-btn:hover .faulty-letter {
        animation: none;
        text-shadow: none;
        opacity: 1;
      }
      
      .glowing-btn:hover:before {
        filter: blur(1.5em);
        opacity: 1;
      }
      
      .glowing-btn:hover:after {
        opacity: 1;
      }
      
      @keyframes faulty-flicker {
        0% {
          opacity: 0.1;
        }
        2% {
          opacity: 0.1;
        }
        4% {
          opacity: 0.5;
        }
        19% {
          opacity: 0.5;
        }
        21% {
          opacity: 0.1;
        }
        23% {
          opacity: 1;
        }
        80% {
          opacity: 0.5;
        }
        83% {
          opacity: 0.4;
        }
      
        87% {
          opacity: 1;
        }
      }
      
      @keyframes text-flicker {
        0% {
          opacity: 0.1;
        }
      
        2% {
          opacity: 1;
        }
      
        8% {
          opacity: 0.1;
        }
      
        9% {
          opacity: 1;
        }
      
        12% {
          opacity: 0.1;
        }
        20% {
          opacity: 1;
        }
        25% {
          opacity: 0.3;
        }
        30% {
          opacity: 1;
        }
      
        70% {
          opacity: 0.7;
        }
        72% {
          opacity: 0.2;
        }
      
        77% {
          opacity: 0.9;
        }
        100% {
          opacity: 0.9;
        }
      }
      
      @keyframes border-flicker {
        0% {
          opacity: 0.1;
        }
        2% {
          opacity: 1;
        }
        4% {
          opacity: 0.1;
        }
      
        8% {
          opacity: 1;
        }
        70% {
          opacity: 0.7;
        }
        100% {
          opacity: 1;
        }
      }
      
      @media only screen and (max-width: 600px) {
        .glowing-btn{
          font-size: 0.5em;
        }
      }
      




















      :root {
        --card-height: 400px;
        --card-width: calc(var(--card-height) / 1.2);
      }
      * {
        box-sizing: border-box;
      }
   
      .card {
        width: var(--card-width);
        height: var(--card-height);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 0 36px;
        perspective: 2500px;
        margin: 0 50px;
        background: none !important;
        position: relative;
    left: -34px;
      }
      
      .cover-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      
      .wrapper {
        transition: all 0.5s;
        position: absolute;
        width: 100%;
        z-index: -1;
        background: repeating-radial-gradient(rgba(174, 74, 255, 0.486), transparent 120px);
        border-radius: 30px;
        background-image: url('../src//component/image/backcard.png');


      }
      
      .card:hover .wrapper {
        transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
        box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
      }
      
      .wrapper::before,
      .wrapper::after {
        content: "";
        opacity: 0;
        width: 100%;
        height: 80px;
        transition: all 0.5s;
        position: absolute;
        left: 0;
      }
      .wrapper::before {
        top: 0;
        height: 100%;
        background-image: linear-gradient(
          to top,
          transparent 46%,
          rgba(12, 13, 19, 0.5) 68%,
          rgba(12, 13, 19) 97%
        );
      }
      .wrapper::after {
        bottom: 0;
        opacity: 1;
        background-image: linear-gradient(
          to bottom,
          transparent 46%,
          rgba(12, 13, 19, 0.5) 68%,
          rgba(12, 13, 19) 97%
        );
      }
      
      .card:hover .wrapper::before,
      .wrapper::after {
        opacity: 1;
      }
      
      .card:hover .wrapper::after {
        height: 120px;
      }
      .title {
        width: 100%;
        transition: transform 0.5s;
      }
      .card:hover .title {
        transform: translate3d(0%, -50px, 100px);
      }
      
      .character {
        width: 100%;
        opacity: 0;
        transition: all 0.5s;
        position: absolute;
        z-index: -1;
      }
      
      .card:hover .character {
        opacity: 1;
        transform: translate3d(0%, -30%, 100px);
      }
      



.card_text{
  justify-content: center;
  animation: auto;
  align-items: center;
  text-align: center;
  color: #ffffff;
  font-size: 37px;
  font-weight: 900;
  animation: lights 1s 750ms linear infinite;

}      


@keyframes lights {
  0% {
    color: hsl(230, 40%, 80%);
    text-shadow:
      0 0 1em hsla(56, 100%, 50%, 0.2),
      0 0 0.125em hsla(46, 100%, 60%, 0.3),
      -1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
      1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
  }
  
  30% { 
    color: hsl(230, 80%, 90%);
    text-shadow:
      0 0 1em hsla(56, 100%, 50%, 0.5),
      0 0 0.125em hsla(46, 100%, 60%, 0.5),
      -0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
      0.5em 0.125em 0.25em hsla(46, 100%, 60%, 0.4);
  }
  
  40% { 
    color: hsl(230, 100%, 95%);
    text-shadow:
      0 0 1em hsla(51, 100%, 50%, 0.5),
      0 0 0.125em hsla(320, 100%, 90%, 0.5),
      -0.25em -0.125em 0.125em hsla(40, 100%, 60%, 0.2),
      0.25em 0.125em 0.125em hsla(49, 100%, 60%, 0.4);
  }
  
  70% {
    color: hsl(49, 100%, 67%);
    text-shadow:
      0 0 1em hsla(51, 100%, 50%, 0.5),
      0 0 0.125em hsla(54, 100%, 60%, 0.5),
      0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
      -0.5em 0.125em 0.25em hsla(51, 100%, 60%, 0.4);
  }
  
  100% {
    color: hsl(51, 100%, 75%);
    text-shadow:
      0 0 1em hsla(204, 100%, 50%, 0.2),
      0 0 0.125em hsla(54, 100%, 60%, 0.3),
      1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
      -1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
  }
  
}



.h2 {
  margin: auto;
  font-size: 3.5rem;
  font-weight: 300;
 
}

.text_div{
  position: absolute;
  top: 450px;
  left: 60px;
}



.card_under_text{
font-weight: 900;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  display: flex;
  font-size: 15px;
  

}


.zindex{
  z-index: 1000 !important;
}

.h1timer {
  font-size: 22px;
  background: -webkit-linear-gradient(#ffc814, #12ffffef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

      
@media screen and (min-width:1200px) {
    
  .h1timer {
    font-size: 42px;
    background: -webkit-linear-gradient(#ffc814, #12ffffef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
     }

     



















     


   .light{
    animation: lightss 2s 750ms linear infinite;
   }  

   




   

  .head_underz{
    text-align: center;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    animation: lights 1s 750ms linear infinite;
  }
@media screen and (min-width:1200px) {
    .head_underz{
        text-align: center;
        color: #fff;
        font-size: 29px;
        font-weight: 600;
        animation: lights 1s 750ms linear infinite;
      }
    }
















    















































































































    

   .partner_card_pad{
    padding: 30px;
    text-align: center;
   } 

   .card_bg{
    background: transparent !important;
    height: 100% !important;
    width:100% !important
   }

  

   .css-qpjsw6-MuiPaper-root-MuiCard-root {
   
    
    transition: box-shadow 00ms cubic-bezier(0.0, 0, 0.0, 0) 0ms !important;
    box-shadow: none !important;
 
}


 .box_Par {
  flex: 10 0 20%;
  max-width: 100%;
  box-shadow: none !important;
}


.picpa {
  width: 100%;
  height: 15.5rem;
  margin: 0 auto 1.5rem auto;
}


.partner_text{
  font-size: 1.1rem !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}

@media screen and (min-width:1200px) {
    
  .partner_text{
    font-size: 1.5rem !important;
  }
     }


     .img_partner {
      transition: transform .7s ease-in-out;
    }
    .img_partner:hover {
      transform: rotate(360deg);
    }



    









    




































    .containerho{
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      border: 5px solid #b9b9b975;
    }
    
    .containerho:hover .overlay{
      opacity: 1;
      transform: scale(1);
    }
    
    .overlay{
      position: absolute;
      background-color: rgba(2, 46, 48, 0.397);
      width: 100%;
      height: 100%;
      top: 0;
      transform: scale(1.3);
      opacity: 0;
      transition: transform .4s ease 0s;
    }
    
    .overlay span{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      font-size: 0.6em;
      color: #fff;
      font-family: Sans-serif;
    }
    
    .containerho img{
      transition: transform .8s ease;
      width: inherit !important;
      height: inherit !important;
    }
    
    .containerho:hover img{
      transform: scale(1.1);

    }


    .ct_pa{
      padding: 70px;
      background-color: #20014433;
    }


    .pad{
    padding: 30px;
    }




    .font_cards{
font-size: 12px !important;
    }

    @media screen and (min-width:1100px) {
      .font_cards{
        font-size: 40px !important; 
      }
  
      
  
  
      }






 
      
      .store_bg{
        background-color: #160f22 !important;
      }

     .gradient {
        background: linear-gradient(159.30deg, rgba(147, 94, 230, 0.3) 18.43%, rgba(147, 94, 230, 0) 59.83%) !important;
        border-radius: 1.2rem !important;
        border: transparent !important;
        height: 100% !important;
    }




   .vert-move {
      -webkit-animation: mover 1s infinite  alternate;
      animation: mover 1s infinite  alternate;
    
  }
  .vert-move {
      -webkit-animation: mover 1s infinite  alternate;
      animation: mover 1s infinite  alternate;
  }
  @-webkit-keyframes mover {
      0% { transform: translateY(0); }
      100% { transform: translateY(-10px); }
  }
  @keyframes mover {
      0% { transform: translateY(0); }
      100% { transform: translateY(-10px); }
  }
  

  .review{
    border-radius: 10px 10px 0px 0px !important;
}






























/* CSS */
.button-90 {
  color: #fff;
  padding: 15px 25px;
  border-radius: 100px;
  background-color: #4C43CD;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 2px 19px 31px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  cursor: pointer;
}












.button {
  color: #fff;
  font-weight: normal;
  font-size: 15px;
  background: #630fe8;
  border-radius: 50px;
  padding: 10px 30px;
  border: none;
  transition: .5s ease;
  box-shadow: 0 15px 60px -5px rgba(0, 0, 0, .5);
}

.button:hover {
  box-shadow: none;
  cursor: pointer;
}

.button:hover + .drip-1{
  height: 50px;
}

.button:hover ~ .drip-2{
  height: 90px;
}

.button:hover ~ .drip-3{
  height: 30px;
}



.drip-1{
  position:absolute;
  width:19px;
  height:0px;
  border-radius:0 0 21px 21px;
  background:#efd5e7;
  margin: 0px 158px;
  transition: height 0.9s ease;
}


.drip-2{
  position:absolute;
  width:19px;
  height:0px;
  border-radius:0 0 21px 21px;
  background:#efd5e7;
  margin: 0px 140px;
  transition: height 1.5s ease;
}

.drip-3{
  position:absolute;
  width:19px;
  height:0px;
  border-radius:0 0 21px 21px;
  background:#efd5e7;
  margin: 0px 60px;
  transition: height .7s ease;
}













.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
 
}




.background_of_gaming {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100vh;
  background-image: url('./Gaming/maticbg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background_of_gaming::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 166, 0, 0.068); /* Adjust the overlay color and transparency */
}




.background_of_gaming_mb {
  position: relative; /* Ensure the container is positioned */
  margin: 0;
  padding: 0;
  height: 100vh;
  background-image: url('./Gaming/maticmb.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}



.background_of_gaming_mb{
  background-image: url('./Gaming/maticmb.png') ;
  margin: 0;
  padding: 0;
 height: 100vh;
 background-position: center center;
 background-repeat: no-repeat;
  background-size: cover;
}

.head_gaming{
  color: #fff01f;
      font-size: 30px;
      font-weight: 700;
}

@media screen and (min-width:1100px) {
  .head_gaming{
      color: #fff01f;
      font-size: 60px;
      font-weight: 700;
  }
  
  


  }


  .under_gaming{
    color: #fff;
      font-size: 13px;
      font-weight: 400;
  }


  @media screen and (min-width:1100px) {
    .under_gaming{
      color: #fff;
      font-size: 20px;
      font-weight: 400;
    }

    }








    @import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");





.ul {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
  font-size: 40px !important;
  top: 50%;
  transform: translateY(-50%);
}

.li {
  display: inline-block;
  margin: 10px;
   font-size: 14px !important;
}

.download {
  width: 200px;
  height: 55px;

  position: relative;
  color: #fff;
  cursor: pointer;

}

.download > .fa {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.df,
.dfn {
  position: absolute;
  left: 70px;
}

.df {
  top: 20px;
  font-size: .68em;
}

.dfn {
  top: 33px;
  font-size: 1.08em;
}

.download:hover {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}



















         
.btn-gradgold {
  background-image: linear-gradient(to right, #ffb347 0%, #ffcc33  51%, #ffb347  100%);
  margin: 10px;
  font-size: 20px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(0, 0, 0);            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  z-index: 1000000000 !important;
  display: block;
}

.btn-gradgold:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 aspect ratio (height divided by width) */
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}