/* fonts */
.agency {
    font-family: agency-fb, sans-serif;
  }
  .akrobat {
    font-family: Akrobat, sans-serif;
  }
  .poppins {
    font-family: "Poppins", sans-serif;
  }
  /* body */
  html,
  body {
    margin: 0;
    background: #fff;
    font-size: 68% !important;
    color: #0a0a0a;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-family: Akrobat, sans-serif;
  }
  #page {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  body p {
    color: #0a0a0a;
  }
  table,
  tr,
  td {
    font-size: 20px;
  }
  a {
    outline: none;
    text-decoration: none;
  }
  .row {
    max-width: 140rem;
    height: inherit;
    margin: 0 auto;
    padding: 0 10rem;
    position: relative;
    z-index: 5;
  }
  * {
    box-sizing: border-box;
  }
  /* header */
  header#head {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
  }
  header#head .row {
    max-width: 152rem;
  }
  header#head .row .flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4.5rem;
  }
  header#head .row .flex #logo {
    width: 12rem;
    height: 12rem;
    background-image: url("../../../public/images/cc-logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  header#head .row .flex #logo a {
    text-indent: -999rem;
    display: block;
    height: 100%;
  }
  header#head .row .flex nav {
    margin-left: auto;
  }
  header#head .row .flex nav .scroll .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  header#head .row .flex nav .scroll .content ul {
    margin: 0 -2rem 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  header#head .row .flex nav .scroll .content ul li {
    font-family: Akrobat, sans-serif;
    margin: 0;
    padding: 1.1rem 2rem;
    color: #ffffff;
    font-size: 2.2rem;
    line-height: 2.7rem;
    font-weight: 600;
    position: relative;
    white-space: nowrap;
    text-align: right;
  }
  header#head .row .flex nav .scroll .content ul li a {
    color: #fff;
    white-space: nowrap;
  }
  header#head .row .flex nav .scroll .content ul li a:hover:before {
    left: 2rem;
    right: 50%;
  }
  header#head .row .flex nav .scroll .content ul li a:hover:after {
    right: 2rem;
    left: 50%;
  }
  header#head .row .flex nav .scroll .content ul li a:before,
  header#head .row .flex nav .scroll .content ul li a:after {
    content: "";
    display: block;
    height: 0;
    border-bottom: 1px solid #fff;
    position: absolute;
    top: 4rem;
    transition: all 0.3s;
  }
  header#head .row .flex nav .scroll .content ul li a:before {
    left: -50%;
    right: 150%;
  }
  header#head .row .flex nav .scroll .content ul li a:after {
    right: -50%;
    left: 150%;
  }
  header#head .row .flex nav .scroll .content #social {
    margin-left: 4rem;
  }
  header#head .row .flex nav .scroll .content #social ul {
    list-style: none;
    margin: 0 -1rem 0 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  header#head .row .flex nav .scroll .content #social ul li {
    padding: 0 1rem;
    text-align: unset;
  }
  header#head .row .flex nav .scroll .content #social ul li a {
    text-indent: -999rem;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.3s;
    text-align: unset;
  }
  header#head .row .flex nav .scroll .content #social ul li a:hover {
    transform: scale(0.8);
  }
  header#head .row .flex nav .scroll .content #social ul li.telegram a {
    width: 3.3rem;
    height: 3.3rem;
    background-image: url("../../../public/images/ico-telegram.png");
  }
  header#head .row .flex nav .scroll .content #social ul li.twitter a {
    width: 3.1rem;
    height: 3.1rem;
    background-image: url("../../../public/images/ico-twitter.png");
  }
  header#head .row .flex nav .scroll .content #social ul li.discord a {
    width: 3.9rem;
    height: 3.4rem;
    background-image: url("../../../public/images/ico-discord.png");
  }
  header#head .row .flex nav .scroll .content #social ul li.instagram a {
    width: 3rem;
    height: 3rem;
    background-image: url("../../../public/images/ico-instagram.png");
  }
  #mobileTrigger {
    display: none;
    height: 2.8rem;
    width: 4.5rem;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 2rem;
    top: 4.6rem;
    z-index: 110;
    transition: all 0.3s;
  }
  #mobileTrigger div {
    width: 2.5rem;
    height: 2px;
    border-radius: 1px;
    background: #fff;
    margin: 0.8rem 1rem;
    transition: all 0.3s;
  }
  #mobileTrigger.opened {
    position: fixed;
    top: 7.5rem;
  }
  #mobileTrigger.opened div {
    position: absolute;
    top: 2.1rem;
    left: 0;
    background: #fff;
    border: none;
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  #mobileTrigger.opened div + div {
    display: block;
    position: absolute;
    top: 2.1rem;
    left: 0;
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  /* home */
  .page-template-page-home #hero {
    width: 100%;
    height: auto;
    position: relative;
    background: #160f22;
    padding: 0 0 16rem 0;
  }
  .page-template-page-home #hero .bg {
    width: 100%;
    height: 163rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 25rem 0 27rem 0;
    position: absolute;
    z-index: 2;
  }
  .page-template-page-home #hero .bg .gradient-top {
    background: linear-gradient(164.96deg, #160f22 28.1%, rgba(22, 15, 34, 0) 53.81%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    height: 75rem;
  }
  .page-template-page-home #hero .bg .gradient-bottom {
    background: linear-gradient(0deg, #160f22 29.85%, rgba(22, 15, 34, 0) 46.53%);
    position: absolute;
    bottom: -1rem;
    left: -10rem;
    right: 0;
    height: 40rem;
    z-index: 5;
  }
  .page-template-page-home #hero .row {
    max-width: 140rem;
    position: relative;
    z-index: 4;
  }
  .page-template-page-home #hero .row .flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 -5rem;
    position: relative;
    z-index: 3;
    text-align: center;
  }
  .page-template-page-home #hero .row .flex .h1 {
    font-family: agency-fb, sans-serif;
    font-size: 14.4rem;
    line-height: 13rem;
    font-weight: 700;
    margin: 28rem auto 1.2rem auto;
    text-transform: uppercase;
    color: transparent;
    max-width: 115rem;
    -webkit-mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  }
  .page-template-page-home #hero .row .flex .image {
    max-width: 142rem;
    height: auto;
    margin: -8rem 0 0 0;
    position: relative;
    right: -11rem;
    z-index: 2;
  }
  .page-template-page-home #hero .row .flex .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 2;
  }
  .page-template-page-home #hero .row .flex .text {
    max-width: 90rem;
    margin: -18rem auto 0 auto;
    position: relative;
    z-index: 5;
  }
  .page-template-page-home #hero .row .flex .text h2 {
    font-family: Akrobat, sans-serif;
    font-size: 3.7rem;
    line-height: 5.2rem;
    color: #ffffff;
    margin: 0 auto 3rem auto;
    font-weight: 600;
    text-align: center;
    max-width: 86rem;
  }
  .page-template-page-home #hero .row .flex .text p {
    font-family: Akrobat, sans-serif;
    font-size: 2.3rem;
    line-height: 3.4rem;
    color: #ffffff;
    margin: 0 auto 2.5rem auto;
    font-weight: 600;
    text-align: center;
    max-width: 63.5rem;
  }
  .page-template-page-home #hero .row .flex .text h3 {
    font-family: agency-fb, sans-serif;
    font-size: 5.4rem;
    line-height: 5.6rem;
    font-weight: 700;
    color: #10dce9;
    margin: 0 auto 3rem auto;
    text-align: center;
    text-transform: uppercase;
  }
  .page-template-page-home #hero .row .flex .box {
    max-width: 42rem;
    height: auto;
    position: absolute;
    left: -3rem;
    top: 85rem;
    z-index: 5;
  }
  .page-template-page-home #hero .row .flex .box h2 {
    font-family: Akrobat, sans-serif;
    font-size: 3.1rem;
    line-height: 3.6rem;
    color: #ffffff;
    margin: 0 auto 2.5rem auto;
    font-weight: 700;
    text-align: center;
  }
  .page-template-page-home #hero .row .flex .box a {
    font-family: Akrobat, sans-serif;
    display: inline-block;
    font-size: 2.6rem;
    line-height: 2.2rem;
    color: #1d094a;
    margin: 0 auto;
    font-weight: 700;
    padding: 2.3rem 4.8rem;
    background-color: #f0ea4c;
    border-radius: 6px;
    transition: all 0.3s;
    background-image: url("../../../public/images/cc-trans.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .page-template-page-home #hero .row .flex .box a:hover {
    color: #1d094a;
    background: #935ee6;
    background-image: url("../../../public/images/cc-trans.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .page-template-page-home #hero .row .flex .play {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    background: transparent;
    border: 2px solid #fff;
    margin: 0 auto;
    position: relative;
    z-index: 5;
  }
  .page-template-page-home #hero .row .flex .play a {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../../../public/images/ico-play.png");
    background-repeat: no-repeat;
    background-size: 3rem;
    background-position: center right 1.8rem;
    transition: all 0.3s;
  }
  .page-template-page-home #hero .row .flex .play a:hover {
    transform: scale(0.9);
  }
  .page-template-page-home #hero .row .flex .button {
    margin: 3.3rem auto 0 auto;
    position: relative;
    z-index: 5;
  }
  .page-template-page-home #hero .row .flex .button a {
    font-family: Akrobat, sans-serif;
    display: inline-block;
    font-size: 2rem;
    line-height: 2.2rem;
    color: #ffffff;
    margin: 0 auto;
    font-weight: 700;
    padding: 1.5rem 3.4rem;
    background-color: #935ee6;
    border-radius: 6px;
    transition: all 0.3s;
  }
  .page-template-page-home #hero .row .flex .button a:hover {
    color: #935ee6;
    background: #fff;
  }
  .page-template-page-home #hero .row .flex .gradient-bottom {
    background: linear-gradient(164.78deg, #160f22 29.85%, rgba(22, 15, 34, 0) 46.53%);
    transform: rotate(-180deg);
    position: absolute;
    bottom: 20rem;
    width: 108vw;
    height: 150rem;
    z-index: 3;
    left: calc((50vw - 72rem) * -1);
  }
  .page-template-page-home #roadmap {
    width: 100%;
    height: auto;
    position: relative;
    background: #160f22;
    padding: 0 0 16rem 0;
  }
  .page-template-page-home #roadmap .bg {
    width: 100%;
    height: 163rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 25rem 0 27rem 0;
    position: absolute;
    z-index: 2;
    transform: rotate(-180deg);
  }
  .page-template-page-home #roadmap .bg .gradient-top {
    background: linear-gradient(0deg, #160f22 40.41%, rgba(22, 15, 34, 0) 110.58%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    height: 120rem;
  }
  .page-template-page-home #roadmap .bg .gradient-bottom {
    background: linear-gradient(180deg, #160f22 29.85%, rgba(22, 15, 34, 0) 46.53%);
    position: absolute;
    top: -1rem;
    left: 0;
    right: 0;
    height: 50rem;
    z-index: 5;
  }
  .page-template-page-home #roadmap .row {
    max-width: 140rem;
    position: relative;
    z-index: 4;
  }
  .page-template-page-home #roadmap .row header {
    text-align: center;
    padding: 19rem 0 8rem 0;
  }
  .page-template-page-home #roadmap .row header p {
    font-family: agency-fb, sans-serif;
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 700;
    color: #10dce9;
    margin: 0 auto 2.5rem auto;
    text-transform: uppercase;
  }
  .page-template-page-home #roadmap .row header h2 {
    font-family: agency-fb, sans-serif;
    font-size: 18.4rem;
    line-height: 16rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto 1.2rem auto;
    text-transform: uppercase;
    color: transparent;
    max-width: 115rem;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    max-width: 51rem;
    position: relative;
    -webkit-mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  }
  .page-template-page-home #roadmap .row .axis {
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    height: unset;
    position: absolute;
    top: 60rem;
    left: 50%;
    bottom: -18rem;
    display: block;
  }
  .page-template-page-home #roadmap .row .flex {
    display: flex;
    border: ;
    align-items: center;
    margin: 0 -9rem 3rem -9rem;
  }
  .page-template-page-home #roadmap .row .flex .box {
    flex: 1 0 50%;
    max-width: 50%;
    padding: 0 9rem;
    order: 1;
    position: relative;
  }
  .page-template-page-home #roadmap .row .flex .box .frame {
    padding: 0.8rem;
    flex: 1;
    max-width: 42rem;
    background: linear-gradient(157.98deg, rgba(94, 38, 173, 0.5) 7.96%, rgba(94, 38, 173, 0.11) 81.66%);
    border-radius: 1.2rem;
  }
  .page-template-page-home #roadmap .row .flex .box .content {
    padding: 5rem 4.5rem;
    background: linear-gradient(157.98deg, rgba(75, 36, 163, 0.5) 7.96%, rgba(29, 9, 74, 0.5) 81.66%);
    backdrop-filter: blur(20px);
    border-radius: 12px;
  }
  .page-template-page-home #roadmap .row .flex .box .content h3 {
    font-family: agency-fb, sans-serif;
    font-size: 3rem;
    line-height: 3.4rem;
    font-weight: 700;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
  }
  .page-template-page-home #roadmap .row .flex .box .content ul {
    margin: 3rem 0 0 0;
    padding: 0;
  }
  .page-template-page-home #roadmap .row .flex .box .content ul li {
    font-family: Akrobat, sans-serif;
    font-size: 2.3rem;
    line-height: 3.4rem;
    font-weight: 600;
    color: #fff;
    margin: 0 0 0.5rem 0;
  }
  .page-template-page-home #roadmap .row .flex .box:before {
    content: "";
    width: 5.2rem;
    height: 5.2rem;
    border: 1px solid #808080;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    background: #000000 url("../../../public/images/sigil.svg") no-repeat center;
    background-size: 3.6rem auto;
    z-index: 2;
  }
  .page-template-page-home #roadmap .row .flex .number {
    flex: 1 0 50%;
    max-width: 50%;
    padding: 0 9rem;
    order: 2;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  .page-template-page-home #roadmap .row .flex .number .cont {
    flex: 1;
    max-width: 42rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-template-page-home #roadmap .row .flex .number h2 {
    font-family: agency-fb, sans-serif;
    font-size: 4.5rem;
    line-height: 5rem;
    font-weight: 700;
    color: #fff;
    margin: 0;
    text-transform: uppercase;
    position: relative;
  }
  .page-template-page-home #roadmap .row .flex .number p {
    font-family: agency-fb, sans-serif;
    font-size: 11.4rem;
    line-height: 12rem;
    font-weight: 700;
    color: #fff;
    margin: 0 0 0 2rem;
    text-transform: uppercase;
    color: transparent;
    max-width: 115rem;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    position: relative;
    -webkit-mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  }
  .page-template-page-home #roadmap .row .flex:nth-of-type(3) {
    margin-top: -10rem;
  }
  .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box {
    order: 2;
  }
  .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box:before {
    left: -2.6rem;
    margin-top: -2.6rem;
  }
  .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .number {
    order: 1;
    display: flex;
    justify-content: flex-end;
  }
  .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .number p:before {
    left: 100%;
    margin-left: 2rem;
    right: -999rem;
    content: "";
    height: 1px;
    background: #808080;
    position: absolute;
    top: 50%;
  }
  .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box {
    order: 1;
    display: flex;
    justify-content: flex-end;
  }
  .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box:before {
    right: -2.6rem;
    margin-top: 1.7rem;
  }
  .page-template-page-home #roadmap .row .flex:nth-of-type(even) .number {
    order: 2;
  }
  .page-template-page-home #roadmap .row .flex:nth-of-type(even) .number h2:before {
    right: 0;
    left: -999rem;
    content: "";
    height: 1px;
    background: #808080;
    position: absolute;
    bottom: -2rem;
  }
  .page-template-page-home #heroes {
    background: #160f22;
    padding: 3rem 0 14rem 0;
  }
  .page-template-page-home #heroes .row {
    max-width: 122rem;
  }
  .page-template-page-home #heroes .row header {
    max-width: 50rem;
    height: auto;
    margin: 0 auto 18rem auto;
    text-align: center;
  }
  .page-template-page-home #heroes .row header p {
    font-family: agency-fb, sans-serif;
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 700;
    color: #10dce9;
    margin: 0 auto 2.5rem auto;
    text-transform: uppercase;
  }
  .page-template-page-home #heroes .row header h2 {
    font-family: agency-fb, sans-serif;
    font-size: 18.4rem;
    line-height: 16rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto 1.2rem auto;
    text-transform: uppercase;
    color: transparent;
    max-width: 115rem;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    max-width: 51rem;
    position: relative;
    -webkit-mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  }
  .page-template-page-home #heroes .row .flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -4.4rem;
  }
  .page-template-page-home #heroes .row .flex .box {
    flex: 1 0 33.33%;
    max-width: 33.33%;
    padding: 0 4.4rem 10rem 4.4rem;
  }
  .page-template-page-home #heroes .row .flex .box .frame {
    position: relative;
  }
  .page-template-page-home #heroes .row .flex .box .frame .bg {
    background: linear-gradient(160.98deg, rgba(147, 94, 230, 0.3) 15.05%, rgba(147, 94, 230, 0) 75.85%);
    border-radius: 1.2rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 8.5rem;
    bottom: 5rem;
  }
  .page-template-page-home #heroes .row .flex .box .frame .bg .sigil {
    background-image: url("../../../public/images/logo-sigil.png");
    background-repeat: no-repeat;
    background-size: 23.4rem;
    background-position: center 1rem;
    width: 100%;
    height: 100%;
  }
  .page-template-page-home #heroes .row .flex .box .frame .pic {
    width: auto;
    height: 33.3rem;
    margin: -6rem auto 1rem auto;
    position: relative;
    z-index: 2;
  }
  .page-template-page-home #heroes .row .flex .box .frame .pic img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .page-template-page-home #heroes .row .flex .box .frame .txt {
    text-align: center;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  .page-template-page-home #heroes .row .flex .box .frame .txt h2 {
    font-family: agency-fb, sans-serif;
    font-size: 5rem;
    line-height: 7rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto;
    text-transform: uppercase;
  }
  .page-template-page-home #heroes .row .flex .box .frame .txt p {
    font-family: Akrobat, sans-serif;
    font-size: 2.4rem;
    line-height: 3.4rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    margin: 0 0 0.5rem 0;
  }
  .page-template-page-home #heroes .row .flex .box .frame .txt a {
    text-indent: -999rem;
    background-image: url("../../../public/images/ico-twitter.png");
    background-repeat: no-repeat;
    background-size: 4.2rem;
    background-position: center;
    transition: all 0.3s;
    padding: 1.5rem;
    display: block;
    margin: 3.5rem auto 0 auto;
  }
  .page-template-page-home #heroes .row .flex .box .frame .txt a:hover {
    transform: scale(0.8);
  }
  .page-template-page-home #set1 {
    width: 100%;
    height: auto;
    position: relative;
    background: #160f22;
    padding: 0 0 16rem 0;
    overflow: hidden;
  }
  .page-template-page-home #set1 .bg {
    width: 100%;
    height: 163rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 25rem 0 27rem 0;
    position: absolute;
    z-index: 2;
  }
  .page-template-page-home #set1 .bg .gradient-top {
    background: linear-gradient(164.96deg, #160f22 28.1%, rgba(22, 15, 34, 0) 53.81%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    height: 75rem;
  }
  .page-template-page-home #set1 .bg .gradient-bottom {
    background: linear-gradient(0deg, #160f22 29.85%, rgba(22, 15, 34, 0) 46.53%);
    position: absolute;
    bottom: -1rem;
    left: -10rem;
    right: 0;
    height: 120rem;
    z-index: 5;
  }
  .page-template-page-home #set1 .row {
    max-width: 144rem;
  }
  .page-template-page-home #set1 .row h2 {
    font-family: agency-fb, sans-serif;
    font-size: 13.8rem;
    line-height: 12rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto 2rem auto;
    text-transform: uppercase;
    color: transparent;
    max-width: 115rem;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    max-width: 51rem;
    position: relative;
    text-align: center;
    -webkit-mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  }
  .page-template-page-home #set1 .row h3 {
    font-family: agency-fb, sans-serif;
    font-size: 5rem;
    line-height: 5rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto 1rem auto;
    text-transform: uppercase;
    text-align: center;
  }
  .page-template-page-home #set1 .row h4 {
    font-family: Akrobat, sans-serif;
    font-size: 3rem;
    line-height: 3.4rem;
    font-weight: 600;
    color: #fff;
    margin: 0 auto 2.5rem auto;
    text-align: center;
  }
  .page-template-page-home #set1 .row p {
    max-width: 55rem;
    font-family: Akrobat, sans-serif;
    font-size: 2.3rem;
    line-height: 3.4rem;
    font-weight: 600;
    color: #fff;
    margin: 0 auto 0 auto;
    text-align: center;
  }
  .page-template-page-home #set1 .row .timer {
    margin: 7rem 0 10rem 0;
  }
  .page-template-page-home #set1 .row .timer .clock-canvas {
    position: absolute;
    left: -999rem;
    width: 100px;
    height: 100px;
  }
  .page-template-page-home #set1 .row .timer .countdown-container.container {
    display: flex;
    justify-content: center;
    margin: 0 -2rem;
  }
  .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item {
    padding: 0 2rem;
  }
  .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item .text p.val {
    font-family: agency-fb, sans-serif;
    font-size: 10rem;
    line-height: 10rem;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin: 0;
  }
  .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item .text p.type-time {
    font-family: agency-fb, sans-serif;
    font-size: 3rem;
    line-height: 3.4rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    margin: 1rem 0 0 0;
  }
  .page-template-page-home #set1 .row .flex {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -1rem;
  }
  .page-template-page-home #set1 .row .flex .box {
    flex: 1 0 25%;
    max-width: 25%;
    padding: 0 1rem 2.5rem 1rem;
  }
  .page-template-page-home #set1 .row .flex .box .frame {
    padding: 0.6rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 1.2rem;
  }
  .page-template-page-home #set1 .row .flex .box .frame .pic {
    height: 30rem;
  }
  .page-template-page-home #set1 .row .flex .box .frame .pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
    object-position: center top;
  }
  .page-template-page-home #set1 .row .flex .box .frame .name {
    background-color: #160f22;
    padding: 1.5rem 2rem;
    border-top: 2px solid #160f22;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    margin-top: -2px;
  }
  .page-template-page-home #set1 .row .flex .box .frame .name p {
    font-family: Akrobat, sans-serif;
    font-size: 2.3rem;
    line-height: 3.3rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin: 0 auto;
  }
  .page-template-page-home #set2 {
    background-color: #160f22;
    padding: 0 0 22rem 0;
  }
  .page-template-page-home #set2 .row {
    max-width: 130rem;
  }
  .page-template-page-home #set2 .row .logo {
    width: 17.3rem;
    height: 17.5rem;
    margin: 0 auto;
  }
  .page-template-page-home #set2 .row .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .page-template-page-home #set2 .row h2 {
    font-family: agency-fb, sans-serif;
    font-size: 13.8rem;
    line-height: 12rem;
    font-weight: 700;
    color: #fff;
    margin: 3rem auto 2rem auto;
    text-transform: uppercase;
    color: transparent;
    max-width: 115rem;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    max-width: 51rem;
    position: relative;
    text-align: center;
    -webkit-mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  }
  .page-template-page-home #set2 .row h3 {
    font-family: agency-fb, sans-serif;
    font-size: 5rem;
    line-height: 5rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto 1rem auto;
    text-transform: uppercase;
    text-align: center;
  }
  .page-template-page-home #set2 .row h4 {
    font-family: Akrobat, sans-serif;
    font-size: 3rem;
    line-height: 3.4rem;
    font-weight: 600;
    color: #fff;
    margin: 0 auto 2.5rem auto;
    text-align: center;
  }
  .page-template-page-home #set2 .row .bg {
    max-width: 124rem;
    height: auto;
    margin: 2rem auto 0 auto;
  }
  .page-template-page-home #set2 .row .bg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .page-template-page-home #set2 .row .bg.mob {
    display: none;
  }
  .page-template-page-home #set3 {
    width: 100%;
    height: auto;
    position: relative;
    background: #160f22;
    padding: 0 0 16rem 0;
    overflow: hidden;
  }
  .page-template-page-home #set3 .bg {
    width: 100%;
    height: 163rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 25rem 0 27rem 0;
    position: absolute;
    z-index: 2;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .page-template-page-home #set3 .bg .gradient-top {
    background: linear-gradient(164.96deg, #160f22 28.1%, rgba(22, 15, 34, 0) 53.81%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    height: 75rem;
  }
  .page-template-page-home #set3 .bg .gradient-bottom {
    background: linear-gradient(0deg, #160f22 29.85%, rgba(22, 15, 34, 0) 46.53%);
    position: absolute;
    bottom: -1rem;
    left: -10rem;
    right: 0;
    height: 120rem;
    z-index: 5;
  }
  .page-template-page-home #set3 .row {
    max-width: 138rem;
  }
  .page-template-page-home #set3 .row h2 {
    font-family: agency-fb, sans-serif;
    font-size: 13.8rem;
    line-height: 12rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto 2rem auto;
    text-transform: uppercase;
    color: transparent;
    max-width: 115rem;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    max-width: 51rem;
    position: relative;
    text-align: center;
    -webkit-mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  }
  .page-template-page-home #set3 .row h3 {
    font-family: agency-fb, sans-serif;
    font-size: 5rem;
    line-height: 5rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto 1rem auto;
    text-transform: uppercase;
    text-align: center;
  }
  .page-template-page-home #set3 .row h4 {
    font-family: Akrobat, sans-serif;
    font-size: 3rem;
    line-height: 3.4rem;
    font-weight: 600;
    color: #fff;
    margin: 0 auto 9.5rem auto;
    text-align: center;
  }
  .page-template-page-home #set3 .row .flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -2rem;
  }
  .page-template-page-home #set3 .row .flex .box {
    flex: 1 0 25%;
    max-width: 25%;
    padding: 0 2rem;
  }
  .page-template-page-home #set3 .row .flex .box .pic {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 150%;
    margin: 0 auto;
  }
  .page-template-page-home #set3 .row .flex .box .pic video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .page-template-page-home #set3 .row .flex .box .pic img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .page-template-page-home #set3 .row .more {
    max-width: 114rem;
    margin: 0 auto;
  }
  .page-template-page-home #set3 .row .more header {
    padding-top: 38rem;
    text-align: center;
  }
  .page-template-page-home #set3 .row .more header h2 {
    font-family: agency-fb, sans-serif;
    font-size: 5rem;
    line-height: 5rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto 13rem auto;
    text-transform: uppercase;
    -webkit-mask-image: unset;
    mask-image: unset;
    -webkit-text-stroke-width: 0;
  }
  .page-template-page-home #set3 .row .more .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -3.8rem;
    align-items: stretch;
  }
  .page-template-page-home #set3 .row .more .container .single {
    flex: 1 0 33.33%;
    max-width: 33.33%;
    padding: 0 3.8rem 9.5rem 3.8rem;
    height: 100%;
  }
  .page-template-page-home #set3 .row .more .container .single .gradient {
    background: linear-gradient(157.28deg, rgba(147, 94, 230, 0.3) 14.43%, rgba(147, 94, 230, 0) 49.83%);
    border-radius: 1.2rem;
    border: transparent;
    height: 100%;
  }
  .page-template-page-home #set3 .row .more .container .single .gradient .pic {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    background: linear-gradient(161.2deg, #ffffff 9.51%, rgba(255, 255, 255, 0) 85.73%);
    margin: 0 auto -5.5rem auto;
    padding: 0.5rem;
    position: relative;
    top: -4.5rem;
  }
  .page-template-page-home #set3 .row .more .container .single .gradient .pic .img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
  .page-template-page-home #set3 .row .more .container .single .gradient .pic .img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
  .page-template-page-home #set3 .row .more .container .single .gradient .txt {
    margin: 2.2rem auto 0 auto;
    padding: 0 3rem 3.5rem 3rem;
    text-align: center;
  }
  .page-template-page-home #set3 .row .more .container .single .gradient .txt h3 {
    font-family: agency-fb, sans-serif;
    font-size: 4rem;
    line-height: 7rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto;
    text-transform: uppercase;
  }
  .page-template-page-home #set3 .row .more .container .single .gradient .txt p {
    font-family: Akrobat, sans-serif;
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    margin: 0.5rem auto 0 auto;
    max-width: 25rem;
  }
  .page-template-page-home #set3 .row .more .container .single .gradient .txt p a {
    font-family: Akrobat, sans-serif;
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 400;
    color: #10dce9;
    margin: 0.5rem auto 0 auto;
    text-decoration: underline;
    transition: all 0.3s;
  }
  .page-template-page-home #set3 .row .more .container .single .gradient .txt p a:hover {
    text-decoration: none;
  }
  .page-template-page-home #partners {
    background-color: #160f22;
  }
  .page-template-page-home #partners .row {
    max-width: 122rem;
  }
  .page-template-page-home #partners .row p {
    font-family: agency-fb, sans-serif;
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 700;
    color: #10dce9;
    margin: 0 auto 2.5rem auto;
    text-transform: uppercase;
    text-align: center;
  }
  .page-template-page-home #partners .row h2 {
    font-family: agency-fb, sans-serif;
    font-size: 11.4rem;
    line-height: 10rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto 11.2rem auto;
    text-transform: uppercase;
    color: transparent;
    max-width: 115rem;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    max-width: 51rem;
    position: relative;
    text-align: center;
    -webkit-mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
  }
  .page-template-page-home #partners .row .flex {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .page-template-page-home #partners .row .flex .box {
    flex: 1 0 20%;
    max-width: 20%;
    padding-bottom: 11rem;
  }
  .page-template-page-home #partners .row .flex .box .pic {
    width: 100%;
    height: 15.5rem;
    margin: 0 auto 1.5rem auto;
  }
  .page-template-page-home #partners .row .flex .box .pic img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .page-template-page-home #partners .row .flex .box h3 {
    font-family: Akrobat, sans-serif;
    font-size: 2.4rem;
    line-height: 3.4rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    margin: 0 auto;
    text-align: center;
  }
  .page-template-page-home #join {
    width: 100%;
    height: auto;
    position: relative;
    background: #160f22;
    overflow: hidden;
  }
  .page-template-page-home #join .bg {
    width: 100%;
    height: 163rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    z-index: 2;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .page-template-page-home #join .bg .gradient-top {
    background: linear-gradient(167deg, #160f22 21.41%, rgba(22, 15, 34, 0) 46%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    height: 100%;
  }
  .page-template-page-home #join .bg .gradient-bottom {
    background: linear-gradient(180deg, #160f22 29.85%, rgba(22, 15, 34, 0) 46.53%);
    position: absolute;
    top: -1rem;
    left: 0;
    right: 0;
    height: 50rem;
    z-index: 5;
  }
  .page-template-page-home #join .row {
    max-width: 110rem;
    padding: 25rem 0 27rem 0;
  }
  .page-template-page-home #join .row .txt {
    max-width: 104.4rem;
    background: linear-gradient(180deg, rgba(16, 220, 233, 0.4) 0%, rgba(16, 220, 233, 0) 100%);
    background: transparent;
    border-radius: 1.2rem;
    position: relative;
    padding: 0.2rem 0 0 0.2rem;
  }
  .page-template-page-home #join .row .txt .content {
    padding: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(157.98deg, rgba(75, 36, 163, 0.3) 7.96%, rgba(29, 9, 74, 0.3) 81.66%);
    backdrop-filter: blur(20px);
    border-radius: 1.2rem;
    box-shadow: -1px -1px 1px rgba(16, 220, 233, 0.3);
  }
  .page-template-page-home #join .row .txt .content h2 {
    font-family: agency-fb, sans-serif;
    font-size: 5.4rem;
    line-height: 5.6rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: center;
  }
  .page-template-page-home #join .row .txt .content p {
    font-family: Akrobat, sans-serif;
    font-size: 3.4rem;
    line-height: 4.4rem;
    font-weight: 600;
    color: #ffffff;
    margin: 1.5rem auto 2rem auto;
    text-align: center;
  }
  .page-template-page-home #join .row .txt .content p a {
    font-family: Akrobat, sans-serif;
    font-size: 2rem;
    line-height: 2.2rem;
    font-weight: 700;
    color: #160f22;
    padding: 1.6rem 3.3rem;
    background-color: #10dce9;
    border-radius: 6px;
    transition: all 0.3s;
  }
  .page-template-page-home #join .row .txt .content p a:hover {
    background-color: #160f22;
    color: #10dce9;
  }
  .page-template-page-home #join .row .superhero {
    width: 65.4rem;
    height: 92.9rem;
    position: absolute;
    bottom: -33rem;
    right: -10.5rem;
  }
  .page-template-page-home #join .row .superhero img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  /* buy */
  .page-template-page-buy #hero,
  .page-template-page-checkout #hero {
    padding: 22rem 0 10rem 0;
    background: #160f22;
  }
  .page-template-page-buy #hero #widget,
  .page-template-page-checkout #hero #widget {
    display: flex;
    justify-content: center;
  }
  /* minting */
  .page-template-page-mintings #heros {
    width: 100%;
    height: auto;
    position: relative;
    background: #160f22;
    padding: 18rem 0 18rem 0;
   position: relative;
   top: -50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }
  .page-template-page-mintings #heros .bg {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 25rem 0 27rem 0;
    position: absolute;
    z-index: 2;
    top: -28rem;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    bottom: 0;
  }
  .page-template-page-mintings #heros .bg .gradient-top {
    background: linear-gradient(164.96deg, #160f22 28.1%, rgba(22, 15, 34, 0) 53.81%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    height: 165rem;
  }
  .page-template-page-mintings #heros .bg .gradient-bottom {
    background: linear-gradient(0deg, #160f22 29.85%, rgba(22, 15, 34, 0) 46.53%);
    position: absolute;
    bottom: -1rem;
    left: -10rem;
    right: 0;
    height: 40rem;
    z-index: 5;
  }
  .page-template-page-mintings #heros .row {
    max-width: 140rem;
    position: relative;
    z-index: 4;
  }
  .page-template-page-mintings #heros .row h1 {
    font-family: agency-fb, sans-serif;
    font-size: 14.4rem;
    line-height: 13rem;
    font-weight: 700;
    color: #fff;
    margin: 0 auto 0 auto;
    text-transform: uppercase;
    color: transparent;
    max-width: 68rem;
    text-align: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    -webkit-mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    position: relative;
    z-index: 10;
  }
  .page-template-page-mintings #heros .row .image {
    position: absolute;
    display: contents !important;
    left: 10rem;
    top: -10rem;
    width: 120rem;
    height: 96rem;
    z-index: 3;
    opacity: 0.3;
  }
  .page-template-page-mintings #heros .row .image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .page-template-page-mintings #heros .row .flex {
    display: flex;
    justify-content: space-between;
    margin: -2rem 0 0 0;
    position: relative;
    z-index: 10;
    text-align: center;
  }
  .page-template-page-mintings #heros .row .flex .text {
    flex: 1 0 50%;
    max-width: 50%;
    margin: -1rem auto 0 auto;
    position: relative;
    z-index: 5;
  }
  .page-template-page-mintings #heros .row .flex .text h2 {
    font-family: agency-fb, sans-serif;
    font-size: 3rem;
    line-height: 3.4rem;
    color: #fff;
    margin: 2.5rem auto;
    font-weight: 700;
    text-align: center;
  }
  .page-template-page-mintings #heros .row .flex .text p {
    font-family: Akrobat, sans-serif;
    font-size: 2.3rem;
    line-height: 3.4rem;
    color: #ffffff;
    margin: 0 auto 3rem auto;
    font-weight: 600;
    text-align: center;
    max-width: 61rem;
  }
  .page-template-page-mintings #heros .row .flex .text p:first-of-type {
    font-size: 3.2rem;
    line-height: 4.4rem;
    font-weight: 600;
    max-width: 65rem;
  }
  .page-template-page-mintings #heros .row .flex .text hr {
    margin: 4rem auto;
    max-width: 11rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  .page-template-page-mintings #heros .row .flex .text #social ul {
    list-style: none;
    margin: 0 -1rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-template-page-mintings #heros .row .flex .text #social ul li {
    padding: 0 1rem;
    text-align: unset;
  }
  .page-template-page-mintings #heros .row .flex .text #social ul li a {
    text-indent: -999rem;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.3s;
    text-align: unset;
  }
  .page-template-page-mintings #heros .row .flex .text #social ul li a:hover {
    transform: scale(0.8);
  }
  .page-template-page-mintings #heros .row .flex .text #social ul li.telegram a {
    width: 3.3rem;
    height: 3.3rem;
    background-image: url("../../../public/images/ico-telegram.png");
  }
  .page-template-page-mintings #heros .row .flex .text #social ul li.twitter a {
    width: 3.1rem;
    height: 3.1rem;
    background-image: url("../../../public/images/ico-twitter.png");
  }
  .page-template-page-mintings #heros .row .flex .text #social ul li.discord a {
    width: 3.9rem;
    height: 3.4rem;
    background-image: url("../../../public/images/ico-discord.png");
  }
  .page-template-page-mintings #heros .row .flex .text #social ul li.instagram a {
    width: 3rem;
    height: 3rem;
    background-image: url("../../../public/images/ico-instagram.png");
  }
  .page-template-page-mintings #heros .row .flex .mint {
    flex: 1 0 50%;
    max-width: 50%;
    padding-left: 10rem;
    position: relative;
    z-index: 5;
  }
  .page-template-page-mintings #heros .row .flex .mint .content {
    background: linear-gradient(157.98deg, rgba(75, 36, 163, 0.5) 7.96%, rgba(29, 9, 74, 0.5) 81.66%);
    backdrop-filter: blur(20px);
    border-radius: 1.2rem;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .top {
    padding: 3.5rem;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .top .flex {
    display: flex;
    margin: 0;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(1) {
    justify-content: space-between;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(1) p {
    font-family: agency-fb, sans-serif;
    font-size: 3rem;
    line-height: 3.4rem;
    margin: 0;
    font-weight: 700;
    color: #fff;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(1) .matic p {
    font-family: Akrobat, sans-serif;
    font-size: 1.8rem;
    line-height: 2rem;
    font-weight: 600;
    text-align: right;
    color: rgba(255, 255, 255, 0.5);
    margin: 0 0 0.5rem 0;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(1) .matic img {
    width: 10rem;
    height: auto;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .top hr {
    margin: 2rem auto;
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
  .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(2) {
    justify-content: space-between;
    align-items: flex-end;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(2) p:nth-of-type(1) {
    font-family: Akrobat, sans-serif;
    font-size: 2.4rem;
    line-height: 3rem;
    margin: 0;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    flex: 1;
    text-align: left;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(2) p:nth-of-type(2) {
    font-family: agency-fb, sans-serif;
    font-size: 5rem;
    line-height: 5.4rem;
    margin: 0;
    font-weight: 700;
    color: #ffffff;
    flex: 1;
    text-align: center;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(2) p:nth-of-type(3) {
    font-family: Akrobat, sans-serif;
    font-size: 2.4rem;
    line-height: 3rem;
    margin: 0;
    font-weight: 600;
    color: #ffffff;
    flex: 1;
    text-align: right;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .bottom {
    padding: 3rem 3rem 4rem 3rem;
    background: rgba(0, 0, 0, 0.2);
  }
  .page-template-page-mintings #heros .row .flex .mint .content .bottom p {
    font-size: 2.3rem;
    line-height: 3rem;
    margin: 0 0 2rem 0;
    color: #fff;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .bottom a {
    font-family: Akrobat, sans-serif;
    display: inline-block;
    font-size: 2rem;
    line-height: 2.2rem;
    color: #1d094a;
    margin: 0 auto;
    font-weight: 700;
    padding: 1.6rem 2.5rem;
    background-color: #f0ea4c;
    border-radius: 6px;
    transition: all 0.3s;
  }
  .page-template-page-mintings #heros .row .flex .mint .content .bottom a:hover {
    color: #1d094a;
    background: #935ee6;
  }
  .page-template-page-mintings #heros .row .flex .gradient-bottom {
    background: linear-gradient(164.78deg, #160f22 29.85%, rgba(22, 15, 34, 0) 46.53%);
    transform: rotate(-180deg);
    position: absolute;
    bottom: -28rem;
    width: 108vw;
    height: 120rem;
    z-index: 3;
    left: calc((50vw - 72rem) * -1);
  }
  /* footer */
  footer#footer {
    padding: 3.5rem 0;
    background: #160f22;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    z-index: 5;
  }
  footer#footer .row {
    max-width: 154rem;
  }
  footer#footer .row .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  footer#footer .row .flex .left {
    display: flex;
    align-items: center;
  }
  footer#footer .row .flex .left #footlogo {
    width: 6.6rem;
    height: 6.6rem;
    margin: 0 0 0 0;
    background-image: url("../../../public/images/cc-logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  footer#footer .row .flex .left ul {
    margin: 0 -2rem 0 1rem;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  footer#footer .row .flex .left ul li {
    font-family: Akrobat, sans-serif;
    margin: 0;
    padding: 0 1.5rem;
    color: rgba(255, 255, 255, 0.3);
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 600;
    position: relative;
    white-space: nowrap;
    text-align: right;
  }
  footer#footer .row .flex .left ul li a {
    color: #fff;
  }
  footer#footer .row .flex .left ul li a:hover {
    text-decoration: underline;
  }
  footer#footer .row .flex .left p {
    font-family: Akrobat, sans-serif;
    font-size: 1.3rem;
    line-height: 2.4rem;
    margin: 1rem 0 0 2.5rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
  }
  footer#footer .row .flex .right p {
    font-family: Akrobat, sans-serif;
    font-size: 1.3rem;
    line-height: 2.4rem;
    margin: 1rem 0 0 0;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    display: none;
  }
  footer#footer .row .flex .right #foot-social {
    margin-left: 4rem;
  }
  footer#footer .row .flex .right #foot-social ul {
    list-style: none;
    margin: 0 -2rem 0 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  footer#footer .row .flex .right #foot-social ul li {
    padding: 0 2rem;
    text-align: unset;
  }
  footer#footer .row .flex .right #foot-social ul li a {
    text-indent: -999rem;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.3s;
    text-align: unset;
  }
  footer#footer .row .flex .right #foot-social ul li a:hover {
    transform: scale(0.8);
  }
  footer#footer .row .flex .right #foot-social ul li.email a {
    text-indent: 0;
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.6);
  }
  footer#footer .row .flex .right #foot-social ul li.email a:hover {
    transform: none;
    text-decoration: underline;
  }
  footer#footer .row .flex .right #foot-social ul li.telegram a {
    width: 2.4rem;
    height: 2.4rem;
    background-image: url("../../../public/images/ico-telegram.png");
  }
  footer#footer .row .flex .right #foot-social ul li.twitter a {
    width: 2.2rem;
    height: 2.2rem;
    background-image: url("../../../public/images/ico-twitter.png");
  }
  footer#footer .row .flex .right #foot-social ul li.discord a {
    width: 2.4rem;
    height: 2.8rem;
    background-image: url("../../../public/images/ico-discord.png");
  }
  footer#footer .row .flex .right #foot-social ul li.instagram a {
    width: 2.1rem;
    height: 2.1rem;
    background-image: url("../../../public/images/ico-instagram.png");
  }
  footer#footer .row .flex .right p.copy {
    font-family: Akrobat, sans-serif;
    font-size: 1.3rem;
    line-height: 2.4rem;
    margin: 1rem 0 0 2.5rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.6);
    display: none;
  }
  /* 404 */
  .error404 #p404 {
    padding: 30vh 0 30vh 0;
  }
  .error404 #p404 p {
    margin: 0;
    text-align: center;
  }
  .error404 #p404 p:nth-of-type(1) {
    font-family: agency-fb, sans-serif;
    font-size: 12rem;
    line-height: 8rem;
    font-weight: 700;
  }
  .error404 #p404 p:nth-of-type(2) {
    font-family: Akrobat, sans-serif;
    font-size: 4rem;
    line-height: 5rem;
  }
  /* misc */
  input:focus,
  button:focus,
  textarea:focus,
  select:focus {
    outline: none !important;
  }
  input::-webkit-input-placeholder,
  input::-moz-placeholder,
  input:-ms-input-placeholder,
  input:-moz-placeholder {
    color: rgba(74, 74, 74, 0.6);
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
  }
  img.alignleft {
    float: left;
    margin: 0 2rem 2rem 0;
  }
  img.alignright {
    float: right;
    margin: 0 0 2rem 2rem;
  }
  img.aligncenter {
    display: block;
    margin: 0 auto;
  }
  .hidden {
    visibility: hidden !important;
  }
  .visible {
    visibility: visible !important;
  }
  /* rwd */
  @media only screen and (min-width: 1281px) {
    #head #mainMenu {
      position: static !important;
      display: block !important;
    }
  }
  @media only screen and (max-width: 1280px) {
    header#head #mobileTrigger {
      display: block;
    }
    header#head .row .flex #mainMenu {
      position: fixed;
      width: 100%;
      top: -105vh;
      height: 100vh;
      right: 0;
      left: 0;
      z-index: 50;
      background: rgba(21, 13, 33, 0.9);
      padding: 8rem 0 2rem 0;
      margin: 0;
    }
    header#head .row .flex #mainMenu .scroll {
      overflow-x: hidden;
      height: 100%;
      overflow-y: auto;
      padding: 0 3rem 0 3rem;
    }
    header#head .row .flex #mainMenu .scroll .content {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 0 2rem 0;
    }
    header#head .row .flex nav .scroll .content ul {
      flex-direction: column;
    }
    header#head .row .flex nav .scroll .content ul li {
      padding: 0;
      margin: 2rem 0;
      text-align: center;
    }
    header#head .row .flex nav .scroll .content #social {
      margin-left: 0;
      margin-top: 4rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    header#head .row .flex nav .scroll .content #social ul {
      flex-direction: row;
      margin: 0;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 1540px) {
    .page-template-page-home #hero .row .flex .box {
      left: 5rem;
    }
  }
  @media only screen and (max-width: 1440px) {
    .page-template-page-home #hero .bg {
      height: 80%;
    }
    .page-template-page-home #hero .row .flex .gradient-bottom {
      left: -4rem;
      width: calc(100vw + 8rem);
    }
    .page-template-page-mintings #heros .row .flex .gradient-bottom {
      left: -4rem;
      width: calc(100vw + 8rem);
      height: 70%;
    }
  }
  @media only screen and (max-width: 1250px) {
    .page-template-page-home #hero .row .flex .h1 {
      font-size: 11rem;
      line-height: 11rem;
      max-width: 85rem;
    }
    .page-template-page-mintings #heros .row .h1 {
      font-size: 11rem;
      line-height: 11rem;
      max-width: 66rem;
    }
    .page-template-page-mintings #heros .row .flex {
      margin-top: 2rem;
    }
  }
  @media only screen and (max-width: 1150px) {
    .page-template-page-home #heroes .row .flex {
      margin: 0 -2rem;
    }
    .page-template-page-home #heroes .row .flex .box {
      padding: 0 2rem 10rem 2rem;
    }
    .page-template-page-home #set3 .row .more .container {
      margin: 0 -2rem;
    }
    .page-template-page-home #set3 .row .more .container .single {
      padding: 0 2rem 9.5rem 2rem;
    }
    .page-template-page-home #join .row {
      padding: 15rem 3rem;
    }
    .page-template-page-mintings #heros .row > .flex {
      flex-direction: column;
      align-items: center;
    }
    .page-template-page-mintings #heros .row > .flex .text {
      flex: unset;
      max-width: unset;
    }
    .page-template-page-mintings #heros .row > .flex .mint {
      flex: unset;
      max-width: 54rem;
      width: 100%;
      padding-left: 0;
      margin-top: 8rem;
    }
    .page-template-page-mintings #heros .row > .flex .image {
      width: 100rem;
      height: 68rem;
      left: 0;
    }
    footer#footer .row .flex {
      flex-direction: column;
    }
    footer#footer .row .flex .left {
      flex-direction: column;
    }
    footer#footer .row .flex .left #footlogo {
      margin: 0 0 3rem 0;
    }
    footer#footer .row .flex .left ul {
      margin: 0;
      justify-content: center;
    }
    footer#footer .row .flex .left p {
      display: none;
    }
    footer#footer .row .flex .right {
      margin: 3rem 0 0 0;
    }
    footer#footer .row .flex .right p.copy {
      display: block;
    }
    footer#footer .row .flex .right #foot-social {
      margin: 0;
    }
    footer#footer .row .flex .right #foot-social ul {
      flex-wrap: wrap;
      max-width: 26rem;
      margin: 0 -2rem;
    }
    footer#footer .row .flex .right #foot-social ul li:nth-of-type(1) {
      flex: 1 0 100%;
      max-width: 100%;
      text-align: center;
      margin: 0 0 3rem 0;
    }
  }
  @media only screen and (max-width: 1100px) {
    .page-template-page-home #hero .row .flex .box {
      left: 50%;
      margin-left: -19rem;
      width: 38rem;
      top: 78vw;
    }
    .page-template-page-home #hero .row .flex .box h2 {
      text-shadow: 0 0 3rem #000;
    }
  }
  @media only screen and (max-width: 1050px) {
    .page-template-page-home #join .row .superhero {
      width: 50rem;
      height: 80rem;
      right: -3rem;
      bottom: -36rem;
    }
  }
  @media only screen and (max-width: 990px) {
    .page-template-page-home #partners .row .flex .box {
      flex: 1 0 25%;
      max-width: 25%;
    }
  }
  @media only screen and (max-width: 960px) {
    .page-template-page-home #hero {
      padding: 0 0 12rem 0;
    }
    .page-template-page-home #hero .row .flex {
      margin: 0;
    }
    .page-template-page-home #hero .row .flex .h1 {
      max-width: 75rem;
      font-size: 9rem;
      line-height: 9rem;
    }
    .page-template-page-home #hero .row .flex .box {
      top: 80vw;
    }
    .page-template-page-home #hero .row .flex .box h2 {
      font-size: 2.6rem;
      line-height: 3.2rem;
      margin: 0 auto 1.5rem auto;
    }
    .page-template-page-home #hero .row .flex .image {
      right: unset;
    }
    .page-template-page-home #hero .row .flex .text {
      max-width: 100%;
      padding-top: 14rem;
    }
    .page-template-page-home #hero .row .flex .text h2 {
      font-size: 3rem;
      line-height: 3.6rem;
      max-width: 62rem;
      margin: 0 auto 2rem auto;
    }
    .page-template-page-home #hero .row .flex .text p {
      font-size: 2rem;
      line-height: 3rem;
      max-width: 52rem;
    }
    .page-template-page-home #roadmap .row header {
      padding: 12rem 0 8rem 0;
    }
    .page-template-page-home #roadmap .row header h2 {
      font-size: 12rem;
      line-height: 11rem;
      max-width: 42rem;
    }
    .page-template-page-home #roadmap .row .axis {
      top: 44rem;
    }
    .page-template-page-home #heroes .row header h2 {
      font-size: 12rem;
      line-height: 11rem;
      max-width: 36rem;
    }
    .page-template-page-home #set3 {
      padding: 0 0 4rem 0;
    }
    .page-template-page-home #set3 .row .more header {
      padding-top: 20rem;
    }
    .page-template-page-mintings #heros .row .h1 {
      max-width: 50rem;
      font-size: 9rem;
      line-height: 9rem;
    }
    .page-template-page-mintings #heros .row .flex .text p:first-of-type {
      font-size: 2.6rem;
      line-height: 3.4rem;
      max-width: 55rem;
    }
    .page-template-page-mintings #heros .row .flex .text p {
      font-size: 2rem;
      line-height: 2.8rem;
    }
    .page-template-page-mintings #heros .row .flex .text h2 {
      font-size: 2.6rem;
      line-height: 3rem;
    }
  }
  @media only screen and (max-width: 860px) {
    .page-template-page-home #roadmap .row .flex .box .content {
      padding: 3.5rem 3.5rem;
    }
    .page-template-page-home #roadmap .row .flex .box .content h3 {
      font-size: 2.6rem;
      line-height: 3rem;
    }
    .page-template-page-home #roadmap .row .flex .box .content ul li {
      font-size: 2rem;
      line-height: 3rem;
    }
    .page-template-page-home #heroes .row .flex {
      margin: 0 -4.4rem;
    }
    .page-template-page-home #heroes .row .flex .box {
      flex: 1 0 50%;
      max-width: 50%;
      padding: 0 4.4rem 10rem 4.4rem;
    }
    .page-template-page-home #set1 .row .flex .box {
      flex: 1 0 33.33%;
      max-width: 33.33%;
    }
    .page-template-page-home #set3 .row .flex {
      margin: 0 -1rem;
    }
    .page-template-page-home #set3 .row .flex .box {
      padding: 0 1rem;
    }
    .page-template-page-home #set3 .row .more .container {
      justify-content: center;
    }
    .page-template-page-home #set3 .row .more .container .single {
      flex: 1 0 50%;
      max-width: 36rem;
    }
  }
  @media only screen and (max-width: 800px) {
    .page-template-page-home #hero .row .flex .image {
      width: 80rem;
      left: calc(30vw - 300px);
    }
    .page-template-page-home #hero .row .flex .box {
      top: 77rem;
    }
    .page-template-page-home #hero .row .flex .text {
      padding-top: 20rem;
    }
    .page-template-page-home #partners .row .flex .box {
      flex: 1 0 33.33%;
      max-width: 33.33%;
    }
    .page-template-page-home #join .row {
      padding: 10rem 2rem 20rem 2rem;
    }
  }
  @media only screen and (max-width: 780px) {
    .row {
      padding: 0 2rem;
    }
    .page-template-page-home #hero .row .flex .h1 {
      font-size: 7rem;
      line-height: 7rem;
      max-width: 58rem;
    }
    .page-template-page-home #hero .row .flex .image {
      margin: -6rem 0 0 0;
    }
    .page-template-page-home #hero .row .flex .text h2 {
      font-size: 2.6rem;
      line-height: 3.2rem;
      max-width: 54rem;
    }
    .page-template-page-home #hero .row .flex .text p {
      font-size: 1.8rem;
      line-height: 2.8rem;
      max-width: 48rem;
    }
    .page-template-page-home #hero .row .flex .text h3 {
      font-size: 4.4rem;
      line-height: 4.6rem;
    }
    .page-template-page-home #roadmap .row .axis {
      left: 4rem;
      top: 52rem;
      bottom: 0;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even),
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 0 0 7rem;
      margin: 0 0 3rem 0;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .number,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .number {
      order: 1;
      flex: unset;
      max-width: unset;
      padding: 0;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .number h2:before,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .number h2:before {
      display: none;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box {
      order: 2;
      flex: unset;
      max-width: unset;
      padding: 0;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box:before,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box:before {
      top: -8.8rem;
      left: -7.7rem;
      margin-top: 0;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box .frame,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box .frame {
      max-width: 54rem;
      width: 100%;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box .content ul li br,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box .content ul li br {
      content: "";
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box .content ul li br:before,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box .content ul li br:before {
      content: " ";
    }
    .page-template-page-home #heroes .row .flex {
      margin: 0 -2rem;
    }
    .page-template-page-home #heroes .row .flex .box {
      padding: 0 2rem 10rem 2rem;
    }
    .page-template-page-home #set3 .row .more header h2 {
      font-size: 4rem;
      line-height: 4rem;
    }
    .page-template-page-mintings #heros .row > .flex .image {
      width: 120vw;
      top: -13rem;
    }
    footer#footer .row .flex .left ul {
      flex-wrap: wrap;
      max-width: 40rem;
    }
    footer#footer .row .flex .left ul li {
      margin: 1rem 0;
    }
  }
  @media only screen and (max-width: 650px) {
    .page-template-page-home #heroes .row .flex .box .frame .txt p {
      font-size: 2rem;
      line-height: 2.6rem;
      margin: 0;
    }
    .page-template-page-home #heroes .row .flex .box .frame .txt h2 {
      font-size: 4rem;
      line-height: 4.4rem;
      margin: 0 auto 0.5rem auto;
    }
    .page-template-page-home #heroes .row .flex .box .frame .txt a {
      margin-top: 2rem;
    }
    .page-template-page-home #set3 .row .flex .box {
      flex: 1 0 50%;
      max-width: 50%;
      margin: 1rem 0;
    }
    .page-template-page-home #partners .row h2 {
      font-size: 9rem;
      line-height: 9rem;
    }
    .page-template-page-home #partners .row p {
      margin: 0 auto 1.5rem auto;
    }
    .page-template-page-home #join .row .txt .content h2 {
      font-size: 4.4rem;
      line-height: 4.8rem;
    }
    .page-template-page-home #join .row .txt .content p {
      font-size: 2.8rem;
      line-height: 3.8rem;
      margin: 1rem auto 1.5rem auto;
    }
    .page-template-page-home #join .row .superhero {
      position: relative;
      width: 100%;
      height: unset;
      margin: -6rem 0 -42rem 0;
      z-index: 5;
      right: unset;
      top: unset;
      bottom: unset;
    }
    .page-template-page-home #join .row .superhero img {
      width: 100%;
      max-width: 40rem;
      height: auto;
      object-position: unset;
      margin: 0 auto;
      display: block;
    }
  }
  @media only screen and (max-width: 600px) {
    .page-template-page-home #set1 .row .flex .box {
      flex: 1 0 50%;
      max-width: 50%;
    }
    .page-template-page-home #partners {
      padding: 0 0 10rem 0;
    }
    .page-template-page-home #partners .row .flex .box {
      padding-bottom: 5rem;
      flex: 1 0 50%;
      max-width: 50%;
    }
  }
  @media only screen and (max-width: 560px) {
    .page-template-page-home #set1 .row h2,
    .page-template-page-home #set2 .row h2,
    .page-template-page-home #set3 .row h2 {
      font-size: 10rem;
      line-height: 10rem;
      margin: 0 auto 1rem auto;
    }
    .page-template-page-home #set1 .row h3,
    .page-template-page-home #set2 .row h3,
    .page-template-page-home #set3 .row h3 {
      font-size: 4rem;
      line-height: 4.4rem;
    }
    .page-template-page-home #set1 .row h4,
    .page-template-page-home #set2 .row h4,
    .page-template-page-home #set3 .row h4 {
      font-size: 2.6rem;
      line-height: 3rem;
    }
    .page-template-page-home #set1 .row p,
    .page-template-page-home #set2 .row p,
    .page-template-page-home #set3 .row p {
      font-size: 2rem;
      line-height: 2.6rem;
    }
    .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item {
      padding: 0 1.4rem;
    }
    .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item .text p.val {
      font-size: 8rem;
      line-height: 8rem;
    }
    .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item .text p.type-time {
      font-size: 2.6rem;
      line-height: 3rem;
    }
    .page-template-page-home #set2 .row .logo {
      width: 12rem;
      height: 12rem;
      margin: 0 auto 2rem auto;
    }
    .page-template-page-home #set2 .row .bg.des {
      display: none;
    }
    .page-template-page-home #set2 .row .bg.mob {
      display: block;
      margin-top: 4rem;
    }
    .page-template-page-home #set3 .row .more header {
      padding-top: 14rem;
    }
    .page-template-page-home #set3 .row .more header h2 {
      font-size: 3rem;
      line-height: 3.4rem;
    }
    .page-template-page-home #set3 .row .more .container .single {
      flex: 1 0 100%;
    }
    .page-template-page-mintings #heros .row > .flex {
      margin-top: 4rem;
    }
    .page-template-page-mintings #heros .row > .flex .image {
      width: 60rem;
      left: -20vw;
    }
    .page-template-page-mintings #heros .row > .flex .text p:first-of-type {
      font-size: 2.2rem;
      line-height: 3rem;
    }
    .page-template-page-mintings #heros .row > .flex .text p {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
    .page-template-page-mintings #heros .row > .flex .text h2 {
      font-size: 2.2rem;
      line-height: 2.8rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .page-template-page-home #hero {
      padding: 0 0 8rem 0;
    }
    .page-template-page-home #hero .row .flex .h1 {
      font-size: 5.4rem;
      line-height: 5.4rem;
    }
    .page-template-page-home #hero .row .flex .image {
      margin: -5rem 0 0 0;
    }
    .page-template-page-home #hero .row .flex .text h2 {
      font-size: 2.2rem;
      line-height: 2.8rem;
    }
    .page-template-page-home #hero .row .flex .text h3 {
      font-size: 3.6rem;
      line-height: 4.2rem;
    }
    .page-template-page-home #hero .row .flex .box {
      width: unset;
      left: 0;
      right: 0;
      margin-left: 0;
    }
    .page-template-page-home #hero .row .flex .box h2 {
      font-size: 2.2rem;
      line-height: 2.8rem;
    }
    .page-template-page-home #roadmap {
      padding: 0 0 10rem 0;
    }
    .page-template-page-home #roadmap .row header {
      padding: 8rem 0 8rem 0;
    }
    .page-template-page-home #roadmap .row header p {
      font-size: 2.4rem;
      line-height: 3rem;
      margin: 0 auto 1.5rem auto;
    }
    .page-template-page-home #roadmap .row header h2 {
      font-size: 9rem;
      line-height: 8.5rem;
      max-width: 30rem;
    }
    .page-template-page-home #roadmap .row .axis {
      top: 42rem;
    }
    .page-template-page-home #heroes .row header p {
      font-size: 2.4rem;
      line-height: 3rem;
      margin: 0 auto 1.5rem auto;
    }
    .page-template-page-home #heroes .row header h2 {
      font-size: 9rem;
      line-height: 8.5rem;
      max-width: 28rem;
    }
    .page-template-page-home #heroes .row .flex {
      justify-content: center;
    }
    .page-template-page-home #heroes .row .flex .box {
      flex: 1 0 100%;
      max-width: 38rem;
    }
    .page-template-page-home #set3 {
      padding: 0;
    }
    .page-template-page-home #partners {
      padding: 0 0 6rem 0;
    }
    .page-template-page-home #partners .row h2 {
      font-size: 7rem;
      line-height: 7rem;
      margin: 0 auto 6rem auto;
    }
    .page-template-page-home #partners .row p {
      font-size: 2.4rem;
      line-height: 3rem;
      margin: 0 auto 1rem auto;
    }
    .page-template-page-home #join .row {
      padding-top: 5rem;
    }
    .page-template-page-home #join .row .txt .content {
      padding: 4rem 2rem;
    }
    .page-template-page-home #join .row .txt .content h2 {
      font-size: 3.8rem;
      line-height: 4.2rem;
    }
    .page-template-page-home #join .row .txt .content p {
      font-size: 2.2rem;
      line-height: 3rem;
    }
    .page-template-page-home #join .row .txt .content p a {
      display: inline-block;
    }
    .page-template-page-mintings #heros .row .h1 {
      font-size: 5.4rem;
      line-height: 5.4rem;
      max-width: 30rem;
    }
    .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(1) {
      flex-direction: column;
    }
    .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(1) .matic {
      order: 1;
      margin: 0 0 2rem 0;
    }
    .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(1) .matic p {
      text-align: center;
    }
    .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(1) p {
      order: 2;
    }
    .page-template-page-mintings #heros .row .flex .mint .content .top hr {
      margin: 4rem 0;
    }
    .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(2) {
      flex-direction: column;
      align-items: center;
    }
    .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(2) p:nth-of-type(1) {
      text-align: center;
      margin: 0 0 1rem 0;
    }
    .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(2) p:nth-of-type(2) {
      text-align: center;
    }
    .page-template-page-mintings #heros .row .flex .mint .content .top .flex:nth-of-type(2) p:nth-of-type(3) {
      text-align: center;
    }
  }
  @media only screen and (max-width: 440px) {
    .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item {
      padding: 0 0.8rem;
    }
    .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item .text p.val {
      font-size: 6rem;
      line-height: 6rem;
    }
    .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item .text p.type-time {
      font-size: 2.2rem;
      line-height: 2.6rem;
    }
    .page-template-page-home #partners .row .flex .box {
      padding-bottom: 4rem;
    }
    .page-template-page-home #partners .row .flex .box .pic {
      height: 10rem;
    }
    .page-template-page-home #partners .row .flex .box h3 {
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }
  @media only screen and (max-width: 400px) {
    .page-template-page-home #roadmap .row .axis {
      left: 3.5rem;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even),
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) {
      padding: 0 0 0 5.5rem;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .number h2,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .number h2 {
      font-size: 3.5rem;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .number p,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .number p {
      font-size: 7rem;
      margin: 0 0 0 1.5rem;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box:before,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box:before {
      width: 4.2rem;
      height: 4.2rem;
      background-size: 2.6rem auto;
      left: -6.3rem;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box .content h3,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box .content h3 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box .content ul,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box .content ul {
      margin: 2rem 0 0 0;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box .content ul li,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box .content ul li {
      font-size: 1.8rem;
      line-height: 2.6rem;
    }
    .page-template-page-home #set1 .row h2,
    .page-template-page-home #set2 .row h2,
    .page-template-page-home #set3 .row h2 {
      font-size: 8rem;
      line-height: 8rem;
    }
    .page-template-page-home #set1 .row h3,
    .page-template-page-home #set2 .row h3,
    .page-template-page-home #set3 .row h3 {
      font-size: 3.6rem;
      line-height: 4rem;
    }
    .page-template-page-home #set1 .row h4,
    .page-template-page-home #set2 .row h4,
    .page-template-page-home #set3 .row h4 {
      font-size: 2.2rem;
      line-height: 2.8rem;
    }
    .page-template-page-home #set1 .row p,
    .page-template-page-home #set2 .row p,
    .page-template-page-home #set3 .row p {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
    .page-template-page-home #set1 {
      padding: 0 0 10rem 0;
    }
    .page-template-page-home #set1 .row .flex .box .frame .pic {
      height: 16rem;
    }
    .page-template-page-home #set1 .row .flex .box .frame .name {
      padding: 1rem 1rem;
    }
    .page-template-page-home #set1 .row .flex .box .frame .name p {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
    .page-template-page-home #set2 {
      padding: 0 0 15rem 0;
    }
    .page-template-page-home #set2 .row .logo {
      width: 9rem;
      height: 9rem;
    }
    .page-template-page-home #set3 .row .flex .box {
      flex: 1 0 100%;
      max-width: 100%;
    }
    footer#footer .row .flex .right #foot-social ul {
      max-width: 21rem;
    }
    footer#footer .row .flex .right #foot-social ul li {
      padding: 0 1rem;
    }
  }
  @media only screen and (max-width: 350px) {
    .page-template-page-home #roadmap .row .axis {
      display: none;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even),
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) {
      padding: 0;
    }
    .page-template-page-home #roadmap .row .flex:nth-of-type(even) .box:before,
    .page-template-page-home #roadmap .row .flex:nth-of-type(odd) .box:before {
      display: none;
    }
    .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item {
      padding: 0 0.8rem;
    }
    .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item .text p.val {
      font-size: 5rem;
      line-height: 5rem;
    }
    .page-template-page-home #set1 .row .timer .countdown-container.container .clock-item .text p.type-time {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    .page-template-page-home #partners .row .flex .box .pic {
      height: 8rem;
    }
    .page-template-page-home #partners .row .flex .box h3 {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    .page-template-page-mintings #heros .row .flex .text p:first-of-type {
      font-size: 2rem;
      line-height: 2.8rem;
    }
    .page-template-page-mintings #heros .row .flex .text p {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    .page-template-page-mintings #heros .row .flex .text h2 {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }