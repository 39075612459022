@font-face {
  font-family: "Akrobat";
  src: url("fonts/akrobat-bold.woff2") format("woff2"), url("fonts/akrobat-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Akrobat";
  src: url("fonts/akrobat-extrabold.woff2") format("woff2"), url("fonts/akrobat-extrabold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Akrobat";
  src: url("fonts/akrobat-regular.woff2") format("woff2"), url("fonts/akrobat-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Akrobat";
  src: url("fonts/akrobat-semibold.woff2") format("woff2"), url("fonts/akrobat-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Akrobat";
  src: url("fonts/akrobat-light.woff2") format("woff2"), url("fonts/akrobat-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "agency-fb";
  src: url("fonts/agency-fb-bold.woff2") format("woff2"), url("fonts/agency-fb-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
