.css-ft4jij-MuiDivider-root {
    margin: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    border-bottom-width: thin;
    margin-left: 16px;
    margin-right: 16px;
    border: aquamarine;
    background: #fff !important;
    height: 3px !important;
    border-radius: 55px !important;
   
}











































@media (min-width: 768px) {
    /* Styles for desktop */
    .buttonwgami{
         display: flex;
    }
  }

  @media (max-width: 767px) {
    /* Styles for mobile */
    .buttonwgami{
        display: inline;
    }
  }