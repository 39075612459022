.font_under_roads{
    font-size: 46px;
}

@media screen and (min-width:1100px) {
    .font_under_roads{
        font-size: 75px;
    }
    }



.font_under_road{
    font-size: 16px;
}

@media screen and (min-width:1100px) {
    .font_under_road{
        font-size: 25px;
    }
    

    .roadmp{
        background-color: rgba(54, 4, 100, 0.342);
        border-radius: 30px;
        border: 10px solid rgba(166, 199, 255, 0.247);
    }
    


    }
