html,
body {
  margin: 0;
  padding: 0;

}

// ## Default
$hero-color: #FFF;
$hero-height: 100vh;
$hero-width: 100vw;
$hero-bg-mix: rgba(#000000bb, 0.5); // hide with false

.hero {
  display: grid;
  position: relative;
  grid-template-columns: $hero-width;
  grid-template-rows: $hero-height;
  place-items: center;
  overflow: hidden;
  
  will-change: clip-path;

  &__bg,
  &__cnt {
    align-self: center;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  // Images
  &__bg {
    display: grid;
    position: relative;
    z-index: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-items: center;

    will-change: opacity;

    // Smoothing background gradient
    @if ($hero-bg-mix) {
      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 5;
        top: -10%;
        right: -10%;
        bottom: -10%;
        left: -10%;
        background: $hero-bg-mix;
        background-blend-mode: screen;
      }
    }

    & picture {
      display: flex;
      height: $hero-height;
      width: $hero-width;
      animation: scaling-hero-anim 4s 0.25s cubic-bezier(0, 0.71, 0.4, 0.97)
        forwards;
      will-change: transform;
    }

    & img {
      display: block;
      object-fit: cover;
      object-position: 77% 50%;
      height: auto;
      width: 100%;
    }
  }

  &__cnt {
    display: grid;
    position: relative;
    place-items: center;
    z-index: 10;
    color: $hero-color;
    font-size: 2.5vw;
    text-transform: uppercase;
    opacity: 0;
    animation: fade-in 0.75s 1.5s linear forwards;
    
    & svg {
      height: 12vw;
      & path {
        fill: #FFF;
      }
    }
    
    & h1 {
      margin-bottom: 0;
    }
  }
}

// ## Animation
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes scaling-hero-anim {
  from {
    transform: scale(1.25);
  }
  to {
    transform: scale(1.1);
  }
}
@keyframes clip-hero-anim {
  from {
    clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}
